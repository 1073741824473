import React from 'react';
import { Card, Spinner } from 'react-bootstrap';

const GeneratingCard = () => (
  <Card className="mt-5 mb-2 text-center">
    <Card.Body>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <Card.Title className="text-dark mt-3">Generation in Progress</Card.Title>
      <Card.Text>Please wait while your content is being generated. <b>This may take up to 5 minutes.</b></Card.Text>
    </Card.Body>
    <Card.Footer>
      {/* You can add any additional information or links here if needed */}
    </Card.Footer>
  </Card>
);

export default GeneratingCard;