import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate,Link } from "react-router-dom";
import Authorization from "../../Models/Authorization";
import AccessDeniedCard from "../../components/AccessDeniedCard";
import TransactionsWidget from "./components/TransactionsWidget";
import AIRewriterModel from "../../Models/AIRewriterModel";
import SubmissionsWidget from "./components/SubmissionsWidget";

const AIRewriter = () => {
    const [hasRole, setHasRole] = useState(false);
    const am = new Authorization();
    const airm = new AIRewriterModel();
    const navigate = useNavigate();

    useEffect(() => {
        setHasRole(am.hasRole('AIRR', 2));
    }, []);

    const handleTabSelect = (key) => {
        // Update the hidden 'type' field in the forms
        document.querySelectorAll('input[name="type"]').forEach(input => {
            input.value = key === 'fromSource' ? 'FRSRC' : 'FRTXT';
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        // Debugging
        console.log("Form Data: ", formData);
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        airm.generate(formData).then((res)=>{
            if (res.data.status === 'failed') {
                alert(res.data.msg);
                return
            }
            navigate(res.data.data.as_id);
        });
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                {hasRole ? (
                    <>
                        <div className="p-3 p-lg-4">
                            <Row>
                                <TransactionsWidget />
                                <SubmissionsWidget />
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>AI Rewriter</Card.Title>
                                            <Card.Text>Welcome!</Card.Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <Tabs defaultActiveKey="fromSource" id="formTabs" onSelect={handleTabSelect}>
                                                <Tab eventKey="fromSource" title="From Source">
                                                    <Form onSubmit={handleSubmit}>
                                                        <input type="hidden" name="type" value="FRSRC" />
                                                        <div className="mb-4">
                                                            <Form.Label>Link to Source</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter the link to the source"
                                                                name="link"
                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Form.Label>Additional Instructions</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Enter additional instructions"
                                                                name="additionalInstructions"
                                                            />
                                                        </div>
                                                        <Button type="submit" variant="primary" className="btn-sign">
                                                            Submit
                                                        </Button>
                                                    </Form>
                                                </Tab>
                                                <Tab eventKey="fromText" title="From Text">
                                                    <Form onSubmit={handleSubmit}>
                                                        <input type="hidden" name="type" value="FRTXT" />
                                                        <div className="mb-4">
                                                            <Form.Label>Content</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Paste the content to rewrite here"
                                                                name="text"
                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Form.Label>Additional Instructions</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Enter additional instructions"
                                                                name="additionalInstructions"
                                                            />
                                                        </div>
                                                        <Button type="submit" variant="primary" className="btn-sign">
                                                            Submit
                                                        </Button>
                                                    </Form>
                                                </Tab>
                                            </Tabs>
                                        </Card.Body>
                                        <Card.Footer>
                                            Questions? Look here <Link to="/pages/signup">Docs</Link>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <div className="container">
                        <AccessDeniedCard moduleName="AI Rewriter" />
                    </div>
                )}
            </div>
        </React.Fragment>
    );

};

export default AIRewriter;
