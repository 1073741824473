import Http from "../Http";
// import store from "../containers/App/store";
// import {setListImages} from "../redux/actions/Lists/listActions";
// import {setUser} from "../redux/actions/userActions";

export default class Authorization {

    isAuthenticated(){
        // check the isAuth var in localstorage is no then false
        if(localStorage.getItem("isAuthenticated") !== null){
            let isAuthenticatedVariable = localStorage.getItem("isAuthenticated");
            if(isAuthenticatedVariable === 'N'){
                console.log("not authed");
                return false
            }
        }

        // check the timer if its past then false
        if(localStorage.getItem("expire_at") !== null){
            let expireTime = localStorage.getItem("expire_at");
            if((Date.now()/1000)>expireTime){
                console.log("Time expired");
                return false;
            }else
                return true;
        }

        if(localStorage.getItem("isAuthenticated") !== null){
            let isAuthenticatedVariable = localStorage.getItem("isAuthenticated");
            if(isAuthenticatedVariable === 'Y'){
                console.log("True");
                return true;
            }else
                return false;
        }
        console.log("Nothing Hit");
    }

    hasRole(roleId,orgId){
        // CHECK IF THE USER HAS A ROLE IN THEIR JWT
        // THIS IS IN THE localstorage.user.roles
        return true;
    }

    //currently not used
    hasRoleWithPromise(roleId, orgId){
        return new Promise((resolve, reject) => {
            // Check if the user has a role in their JWT
            // This is in the localStorage.user.roles
            // For now, we are resolving the promise with 'true', but you can add your logic here
            try {
                // Add logic to verify the role here
                // Example: if (userHasRole) { resolve(true); } else { resolve(false); }
                resolve(true);
            } catch (error) {
                // If there is an error in the logic, reject the promise
                reject(error);
            }
        });
    }



}