import React, { useState, useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Row, Col, Dropdown,Form } from 'react-bootstrap';
import Http from "../../Http";

const ColumnSelector = ({ columns, visibleColumns, setVisibleColumns }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
    const handleCheckboxClick = (e) => e.stopPropagation();

    const toggleColumn = accessor => {
        const newVisibleColumns = { ...visibleColumns, [accessor]: !visibleColumns[accessor] };
        setVisibleColumns(newVisibleColumns);
    };

    return (
      <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Columns
          </Dropdown.Toggle>

          <Dropdown.Menu>
              {columns.map(column => (
                <Dropdown.Item
                  key={column.accessor}
                  as="button"
                  onClick={() => {
                      setVisibleColumns({
                          ...visibleColumns,
                          [column.accessor]: !visibleColumns[column.accessor]
                      });
                  }}
                  active={visibleColumns[column.accessor]}
                >
                    {column.Header}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
      </Dropdown>
    );
};

const DataTable = ({ endpoint, columns, classNames }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [limit, setLimit] = useState(20); // Initial limit set to 10
    const [search, setSearch] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [visibleColumns, setVisibleColumns] = useState(() => {
        const visibility = {};
        columns.forEach(column => {
            if (column.accessor) {
                visibility[column.accessor] = true;
            }
        });
        return visibility;
    });

    const filteredColumns = useMemo(() => columns.filter(column => !column.accessor || visibleColumns[column.accessor]), [columns, visibleColumns]);

    const fetchData = async ({ pageSize, pageIndex, sortBy, search }) => {
        setLoading(true);
        const sortByData = sortBy[0] || {};
        const formData = new FormData();
        formData.append('limit', pageSize);
        formData.append('page', pageIndex + 1);
        formData.append('sort', sortByData.id || "");
        formData.append('order', sortByData.desc ? 'desc' : 'asc');
        formData.append('term', search || "");

        const response = await Http.post(endpoint, formData);
        setData(response.data.data);
        const totalCount = parseInt(response.headers['x-total-count'], 10);
        setPageCount(Math.ceil(totalCount / pageSize));
        setLoading(false);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { pageSize, sortBy }
    } = useTable(
        {
            columns: filteredColumns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount,
            manualSortBy: true,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        fetchData({ pageIndex: currentPageIndex, pageSize: limit, sortBy, search }); // Changed pageSize to limit
    }, [currentPageIndex, limit, sortBy, search]); // Added limit to dependency array


    return (
      <>
          <Row>
              <Col xs={6} sm={4} className="p-1">
                  <Form.Control
                    type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Search..."
                  />
              </Col>
              <Col xs={4} sm={4} className="p-1 d-none d-sm-block">
                  <Form.Control as="select" value={limit} onChange={e => setLimit(Number(e.target.value))}>
                      <option value={10}>Show 10</option>
                      <option value={20}>Show 20</option>
                      <option value={40}>Show 40</option>
                      <option value={50}>Show 50</option>
                  </Form.Control>
              </Col>
              <Col xs={6} sm={4} className="mb-2 text-right p-1">
                  <ColumnSelector
                    columns={columns}
                    visibleColumns={visibleColumns}
                    setVisibleColumns={setVisibleColumns}
                  />
              </Col>
          </Row>
          <Row>
              <Col xs={12} className="table-responsive">
                  <Table {...getTableProps()} className={'react-table resizable-table ' + classNames}>
                      <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render('Header')}
                                  <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                            </span>
                              </th>
                            ))}
                        </tr>
                      ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                      {loading ? (
                        <tr>
                            <td colSpan={filteredColumns.length}>Loading...</td>
                        </tr>
                      ) : (
                        rows.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                  {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                  ))}
                              </tr>
                            );
                        })
                      )}
                      </tbody>
                  </Table>
              </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center">
              <Col xs={12}>
                  <Pagination className="justify-content-center">
                      <Pagination.Prev
                        disabled={currentPageIndex === 0}
                        onClick={() => setCurrentPageIndex(prev => Math.max(prev - 1, 0))}
                      />
                      <Pagination.Next
                        disabled={currentPageIndex >= pageCount - 1}
                        onClick={() => setCurrentPageIndex(prev => prev + 1)}
                      />
                  </Pagination>
              </Col>
          </Row>
      </>
    );
};

export default DataTable;
