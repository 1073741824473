import React, { useEffect, useState } from "react";
import Header from "../../../../layouts/Header";
import {Button, Card, Col, Dropdown, Form, Nav, ProgressBar, Row, Table,Tab, Tabs} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Authorization from "../../../../Models/Authorization";
import img2 from "../../../../assets/img/img2.jpg";
import AccessDeniedCard from "../../../../components/AccessDeniedCard";
import AIRewriterModel from "../../../../Models/AIRewriterModel";
import DataTable from "../../../../components/DataTable";

const AIRewriterSubmissions=()=> {
  // toggle sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);
  const [hasRole, setHasRole] = useState(false);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const am = new Authorization();
  const { as_id } = useParams(); // Get as_id from URL
  const airm = new AIRewriterModel();

  useEffect(() => {
    setHasRole(am.hasRole('AIRR', 2));
  },[]);

  const columns =[
    {

      Header: "Name",
      Cell: ({ cell }) => {
        // debugger
        return (
          <>
            <a
              href={"./"+cell.row.original.as_id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1">
                {/*<FontAwesomeIcon icon={faExternalLinkAlt} />*/}
                View
              </button>
            </a>
          </>
        )
      }
    },
    {
      Header: 'Model',
      accessor: 'as_id',
      width: 150
    },
    {
      Header: 'Created',
      accessor: 'as_created',
      width: 150
    }
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
              <div>
                  <ol className="breadcrumb fs-sm mb-1">
                      <li className="breadcrumb-item"><Link to="/apps/ai-rewriter">AI Rewriter</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Submission ID</li>
                  </ol>
                  <h4 className="main-title mb-0">Submission</h4>
              </div>
              <div className="d-flex gap-2 mt-3 mt-md-0">
                  <Button variant="" className="btn-white d-flex align-items-center gap-2">
                      <i className="ri-share-line fs-18 lh-1"></i>Share
                  </Button>
                  <Button variant="" className="btn-white d-flex align-items-center gap-2">
                      <i className="ri-printer-line fs-18 lh-1"></i>Print
                  </Button>
                  <Button variant="primary" className="d-flex align-items-center gap-2">
                      <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
                  </Button>
              </div>
          </div>
          {hasRole ? (
              <Row>
                  <Col xs={12}>
                    <Card>
                      <Card.Body>
                        <DataTable columns={columns} endpoint={"/air/getSubmissions"} />
                      </Card.Body>
                    </Card>

                  </Col>
              </Row>
            ):(
              <div className="container">
                  <AccessDeniedCard moduleName="AI Rewriter"/>
              </div>
            )
          }


      </div>
    </React.Fragment>
  )
}

export default AIRewriterSubmissions;