import Http from "../Http";
// import store from "../containers/App/store";
// import {setListImages} from "../redux/actions/Lists/listActions";
// import {setUser} from "../redux/actions/userActions";

export default class AIRewriterModel {

    generate(formData) {

        return Http
            .post("air/generate", formData,{
                headers:{'content-type':'multipart/formData'}
            })
            .then(response => {
                return response;
            });
    }

    getSubmission(formData) {

        return Http
            .post("air/getSubmission", formData)
            .then(response => {
                return response;
            });
    }

    getSubmissions({page,limit,sort}) {
        //console.log(page,limit,sort);
        let fd =new FormData();
        fd.append('page',page);
        fd.append('limit',limit);
        fd.append('sort',sort);

        return Http
            .post("air/getSubmissions", fd)
            .then(response => {
                return response;
            });
    }

    getSubmissionsCount() {

        return Http
            .post("air/getSubmissionsCount")
            .then(response => {
                return response;
            });
    }

    getSubmissionContent(formData) {

        return Http
            .post("air/getSubmissionContent", formData)
            .then(response => {



                return response;

            });
    }

}