import {Card, Col, Row} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import React from "react";

const TransactionsWidget = ()=>{

    var data = [[0, 9], [1, 7], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6]];

    const chart = {
        parentHeightOffset: 0,
        stacked: true,
        sparkline: {
            enabled: true
        }
    };

    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const plotOptions = {
        bar: {
            columnWidth: '60%'
        },
    };

    const stroke = {
        curve: 'straight',
        lineCap: 'square'
    };

    const seriesOne = [{
        type: 'column',
        data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
    }, {
        type: 'column',
        data: data
    }];

    const optionOne = {
        chart: chart,
        states: states,
        colors: ['#506fd9', '#e5e9f2'],
        plotOptions: plotOptions,
        stroke: stroke,
        fill: { opacity: 1 },
        tooltip: { enabled: false }
    };

    return (
        <Col md="6" xl="4">
            <Card className="card-one">
                <Card.Body>
                    <Row>
                        <Col xs="7">
                            <h3 className="card-value mb-1">4,608</h3>
                            <label className="card-title fw-medium text-dark mb-1">Transactions</label>
                            <span className="d-block text-muted fs-11 ff-secondary lh-4">No. of transactions. Click here to view transactions</span>
                        </Col>
                        <Col xs="5">
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={70} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default TransactionsWidget;