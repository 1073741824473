import React, { useEffect, useState } from "react";
import Header from "../../../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Button, Card, Col, Dropdown, Form, Nav, ProgressBar, Row, Table,Tab, Tabs} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Authorization from "../../../../Models/Authorization";
import img2 from "../../../../assets/img/img2.jpg";
import AccessDeniedCard from "../../../../components/AccessDeniedCard";
import TransactionsWidget from "../../components/TransactionsWidget";
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AIRewriterModel from "../../../../Models/AIRewriterModel";
import GeneratingCard from "../../../../components/GeneratingCard";

const AIRewriterResult=()=> {
  const [hasRole, setHasRole] = useState(false);
  const [editorContent, setEditorContent] = useState(null); // State to store editor content
  const [submissionData, setSubmissionData] = useState(null); // State to store editor content
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const am = new Authorization();
  const { as_id } = useParams(); // Get as_id from URL
  const airm = new AIRewriterModel();

  useEffect(() => {
    setHasRole(am.hasRole('AIRR', 2));
  },[]);

  useEffect(() => {
    if (as_id && submissionData === null) {
      fetchSubmissionData();
    }
  }, [as_id, submissionData]);

  useEffect(() => {
    if (submissionData && submissionData.as_meta) {
      // Populate title
      const titleMeta = submissionData.as_meta.find(meta => meta.asm_key === 'article_title');
      if (titleMeta) {
        setTitle(titleMeta.asm_value);
      }

      // Populate tags
      const tagsMeta = submissionData.as_meta.filter(meta => meta.asm_key === 'tag')
        .map(tagMeta => ({ value: tagMeta.asm_value, label: tagMeta.asm_value }));
      setTags(tagsMeta);
    }
  }, [submissionData]);

  useEffect(() => {
    let attemptCount = 0;

    const checkSubmissionStatus = () => {
      if (submissionData !== null) {
        if (submissionData.s_id === 'AIRGI' || submissionData.s_id === 'SUBCR') {
          if (attemptCount < 10) {
            setTimeout(() => {
              fetchSubmissionData(); // function to fetch submission data
              attemptCount++;
            }, 30000); // wait for 30 seconds
          } else {
            alert('Submission Failed or is taking longer than expected');
          }
        } else {
          let formData = new FormData();
          formData.append('as_id', as_id);
          airm.getSubmissionContent(formData).then((res) => {
            if (res.data.status === 'failed') {
              alert(res.data.msg);
              return;
            }
            setEditorContent(res.data.data);
          });
        }
      }
    };

    checkSubmissionStatus();
  }, [submissionData]);


  const fetchSubmissionData=()=>{
    let formData =new FormData();
    formData.append('as_id',as_id);
    airm.getSubmission(formData).then((res)=>{
      if (res.data.status === 'failed') {
        alert(res.data.msg);
        return;
      }
      setSubmissionData(res.data.data);
    });
  }

  const handleCreateTag = (inputValue) => {
    const newTag = { value: inputValue, label: inputValue };
    setTags([...tags, newTag]);
  };

  const customSelectStyles = {
      menu: (provided) => ({ ...provided, zIndex: 9999 })
  };

  const selectOptions = [
      { value: 'option1', label: 'Option 1' },
      { value: 'option2', label: 'Option 2' },
      { value: 'option3', label: 'Option 3' },
      // Add more options as needed
  ];

  // toggle sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
              <div>
                  <ol className="breadcrumb fs-sm mb-1">
                      <li className="breadcrumb-item"><Link to="/apps/ai-rewriter">AI Rewriter</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to="/apps/ai-rewriter/ss">Submissions</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">{as_id}</li>
                  </ol>
                  <h4 className="main-title mb-0">Submission</h4>
              </div>
              <div className="d-flex gap-2 mt-3 mt-md-0">
                  <Button variant="" className="btn-white d-flex align-items-center gap-2">
                      <i className="ri-share-line fs-18 lh-1"></i>Share
                  </Button>
                  <Button variant="" className="btn-white d-flex align-items-center gap-2">
                      <i className="ri-printer-line fs-18 lh-1"></i>Print
                  </Button>
                  <Button variant="primary" className="d-flex align-items-center gap-2">
                      <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
                  </Button>
              </div>
          </div>
          {submissionData && (submissionData.s_id === 'AIRGI' || submissionData.s_id === 'SUBCR') ? (
            <GeneratingCard />
          ) :hasRole && (submissionData.s_id !== 'AIRGI' || submissionData.s_id !== 'SUBCR') ? (
              <Row>
                  <Col xs={12}>
                      <Card>
                          <Card.Body>
                              <Form.Group className="mb-3" controlId="submissionTitle">
                                  <Form.Label>Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter title"
                                  value={title}
                                  onChange={e => setTitle(e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="submissionTitle">
                                  <Form.Label>Tags</Form.Label>
                                <CreatableSelect
                                  isMulti
                                  options={tags}
                                  styles={customSelectStyles}
                                  value={tags}
                                  onChange={setTags}
                                  onCreateOption={handleCreateTag}
                                />
                              </Form.Group>

                              <Editor
                                  initialValue={editorContent}
                                  apiKey="3erpps9wn7tupfhvw5zrc2nuip7lw4j6zqe1g91iynnpak1c"
                                  init={{
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                      ],
                                      toolbar: 'undo redo | formatselect | ' +
                                          'bold italic backcolor | alignleft aligncenter ' +
                                          'alignright alignjustify | bullist numlist outdent indent | ' +
                                          'removeformat | help',
                                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                  onEditorChange={(newContent) => setEditorContent(newContent)}
                              />
                          </Card.Body>
                          <Card.Footer>
                              Questions? Look here <Link to="/pages/signup">Docs</Link>
                          </Card.Footer>
                      </Card>
                  </Col>
              </Row>
            ):(
              <div className="container">
                  <AccessDeniedCard moduleName="AI Rewriter"/>
              </div>
            )
          }


      </div>
    </React.Fragment>
  )
}

export default AIRewriterResult;