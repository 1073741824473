import React,{useState,useEffect} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Authentication from "../Models/Authentication";

export default function Signin() {

  const navigate = useNavigate();
  const am = new Authentication();

  // Initialize state variables
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: ""
  });

  // Function to update the state when inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const { username, password } = loginCredentials;

    am.login(username, password).then(
        (response) => {
          if(response === false)
            return;

          // Check for redirect URL parameter
          const urlParams = new URLSearchParams(window.location.search);
          const redirectUrl = urlParams.get('redirect');

          // Navigate to redirect URL if it exists, otherwise go to homepage
          // EXAMPLE: http://localhost:3001/pages/signin?redirect=/dashboard/finance
          navigate(redirectUrl || "/");
        },
        error => {
          const resMessage =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();

          // Handle error here (e.g., show error message to the user)
        }
    );
  };


  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">dashbyte</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleLogin}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  name="username" // Add name attribute
                  value={loginCredentials.username} // Use state value
                  onChange={handleInputChange} // Handle input change
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="">Forgot password?</Link>
              </Form.Label>
              <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password" // Add name attribute
                  value={loginCredentials.password} // Use state value
                  onChange={handleInputChange} // Handle input change
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>

            <div className="divider"><span>or sign in with</span></div>

            <Row className="gx-2">
              <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
              <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          Don't have an account? <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer>
      </Card>
    </div>
  )
}