import React from 'react';
import {Button, Card} from "react-bootstrap";

const AccessDeniedCard = ({ moduleName }) => (
    <Card className="mt-5 mb-2">

        <Card.Body>
            <Card.Title className="text-dark">{moduleName}</Card.Title>
            <Card.Text>You do not have access to this module. To get access upgrade your account</Card.Text>
            <Button variant="primary">Get Access</Button>
        </Card.Body>
        <Card.Footer>

        </Card.Footer>
    </Card>
);

export default AccessDeniedCard;
